<template>
  <div>
    <create :useObject="1"></create>
  </div>
</template>

<script>
import Create from '../common/Create.vue'
export default {
  components: {
    Create
  }
}
</script>
